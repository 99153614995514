import React, { forwardRef } from "react";
// import MedicalGradeFlowerImage1 from "../../../assets/pages/index/MedicalGradeFlowerImage1.png";
import { PrimaryButton } from "../../Common/Button";
//@ts-ignore
import Product360 from "../../../assets/videos/Product360.mp4";
import { navigate } from "gatsby";

interface ProductSectionProps {
  className?: string;
  animate?: boolean;
}

const ProductSection = forwardRef(({ className, animate }: ProductSectionProps, ref: any) => {
  return (
    <section
      className={`relative h-auto flex flex-col items-center md:px-16 py-10 md:py-20
    transition-all ease-out duration-2500 translate-y-10`}
      style={animate ? { opacity: 1, transform: "translateZ(0px)" } : { opacity: 0 }}
      ref={ref}
      id="capabilities"
    >
      <div
        className="grid grid-cols-12 gap-0 gap-y-0 
        gap-x-[16px] md:gap-x-[40px] xl:gap-x-[100px] 
        w-full max-w-screen-xl px-4 md:px-0
        "
      >
        <div className="col-span-12 md:col-span-6">
          <video
            className="xl:h-[665px] lg:h-[600px] md:h-[500px] sm:h-[450px] h-[400px]
              object-cover w-full rounded-[20px] mb-4 md:mb-0"
            autoPlay
            muted
            loop
            id="myVideo"
            playsInline
          >
            <source src={Product360} type="video/mp4" />
          </video>
        </div>
        <div className="col-span-12 md:col-span-6 flex flex-col justify-center xl:pr-[160px] lg:pr-[80px]">
          <h3 className="mb-4 md:mb-6 text-medMainGreen text-b1 lg:text-h3">Medical Grade Flowers</h3>
          <p className="mb-6 text-cap md:text-b2 text-medMainGreen">
            Our cultivars are meticulously cared for by our cultivation team at our indoor facility. We take great pride to ensure that our customers
            receive consistent top-shelf floral biomass.{" "}
          </p>
          <PrimaryButton onClick={() => navigate("/products")}>Learn more</PrimaryButton>
        </div>
      </div>
    </section>
  );
});

export default ProductSection;
