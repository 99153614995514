import React, { forwardRef } from "react";
import { SecondaryButton } from "../../Common/Button";
import { navigate } from "gatsby";
//@ts-ignore
import BgVideo from "../../../assets/videos/medicana_banner_video_com_3.mp4";
import MedicanaLogo from "../../../assets/logo/medicana_desktop_logo_white.svg";

interface BannerSectionProps {
  className?: string;
  animate: boolean;
}

const BannerSection = forwardRef(({ className, animate }: BannerSectionProps, ref: any) => {
  return (
    <>
      <section className={"relative w-screen h-screen sm:h-screen bg-center bg-cover bg-no-repeat"} ref={ref} id="banner">
        <video
          className="absolute object-cover left-0 h-full top-0 md:top-0 sm:h-full w-screen bg-local overflow-x-hidden z-[0] bg-center bg-cover bg-no-repeat"
          autoPlay
          muted
          loop
          id="myVideo"
          playsInline
        >
          <source src={BgVideo} type="video/mp4" />
        </video>
        {/* <iframe
          width={"100%"}
          height={"100%"}
          src="https://www.youtube.com/embed/NmcneaW4jtw"
          title="YouTube video player"
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        /> */}
        <div className="absolute bottom-0 left-0  bg-gradient-to-t from-[#636363e6] opacity-80 w-full h-1/2 z-10" />

        <div className="sm:mx-8 lg:mx-16 xl:mx-28 2xl:mx-auto max-w-screen-lg h-full xl:max-w-screen-xl mx-auto z-20">
          <div className="flex justify-center items-start md:items-center px-6 mb-0 pb-10 h-full pt-[55%] sm:pt-[200px] md:pt-24 sm:mb-20 xl:mb-0 xl:pt-10 sm:px-6 md:px-0 xl:px-0 max-w-screen-xl xl:pb-[60px]">
            <div
              className={`w-full flex flex-col items-center z-10
              transition-all ease-out duration-2500 translate-y-10`}
              style={animate ? { opacity: 1, transform: "translateZ(0px)" } : { opacity: 0 }}
            >
              <img className="h-[120px] md:h-[194px] md:mb-8 xl:mb-10" src={MedicanaLogo} alt="logo" />
              <h1
                className={`font-medium capitalize mb-4 md:mb-5 lg:mb-6 xl:mb-10 mt-4 md:mt-0 
              text-white md:text-white text-h3-mb lg:text-[32px] xl:text-h3 text-center font-Arcon
              `}
                style={{ fontFamily: "Arcon" }}
              >
                Medical Grade Cannabis Growing Facility
              </h1>
              <div className="flex flex-row gap-x-4 gap-y-6 md:gap-x-8">
                <SecondaryButton onClick={() => navigate("/products#cultivars")}>Cultivars</SecondaryButton>
                <SecondaryButton onClick={() => navigate("/aboutus")}>About us</SecondaryButton>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
});

export default BannerSection;
