import React, { forwardRef } from "react";
import ThrichomeRich from "../../../assets/pages/index/ThrichomeRich.svg";
import YieldControl from "../../../assets/pages/index/YieldControl.svg";
import DenseFlowerStructure from "../../../assets/pages/index/DenseFlowerStructure.svg";
import NatureOptimization from "../../../assets/pages/index/NatureOptimization.svg";
import ContaminantFree from "../../../assets/pages/index/ContaminantFree.svg";
import AutomatedFertigation from "../../../assets/pages/index/AutomatedFertigation.svg";
import LabTested from "../../../assets/pages/index/LabTested.svg";
import DataDriven from "../../../assets/pages/index/DataDriven.svg";

interface OurCapabilitiesSectionProps {
  className?: string;
  animate?: boolean;
}

type FeatureProps = {
  id: string;
  title: string;
  image: string;
};

const CapabilitiesData: FeatureProps[] = [
  {
    id: "1",
    title: "Thrichome Rich",
    image: ThrichomeRich,
  },
  {
    id: "2",
    title: "Yield Control",
    image: YieldControl,
  },
  {
    id: "3",
    title: "Dense Flower Structure",
    image: DenseFlowerStructure,
  },
  {
    id: "4",
    title: "Nature Optimization",
    image: NatureOptimization,
  },
  {
    id: "5",
    title: "Contaminant Free",
    image: ContaminantFree,
  },
  {
    id: "6",
    title: "Automated Fertigation",
    image: AutomatedFertigation,
  },
  {
    id: "5",
    title: "Lab Tested",
    image: LabTested,
  },
  {
    id: "6",
    title: "Data Driven",
    image: DataDriven,
  },
];

const OurCapabilitiesSection = forwardRef(({ className, animate }: OurCapabilitiesSectionProps, ref: any) => {
  return (
    <section
      className={`relative h-auto flex flex-col items-center md:px-16 pt-10 md:pt-20
       transition-all ease-out duration-2500 translate-y-10`}
      style={animate ? { opacity: 1, transform: "translateZ(0px)" } : { opacity: 0 }}
      ref={ref}
      id="capabilities"
    >
      <h3 className="font-semibold sm:font-normal lg:text-h2 sm:text-h3 text-b2 h4 mb-10 md:mb-18 mt-0 text-woodyGreen">
        Premium Cultivars with Passion
      </h3>
      <div className="grid grid-cols-12 gap-0 gap-y-6 md:gap-y-0 w-full px-4 md:px-0 max-w-screen-xl">
        {CapabilitiesData.map((data: FeatureProps) => (
          <div
            className="flex justify-center items-start h-[128px] md:h-[240px] col-span-6 md:col-span-3 
            p-2 mb-10 md:p-6 md:px-0 xl:px-3 
            !bg-center !bg-cover !bg-no-repeat"
            key={data.title}
          >
            <div className="p-0 z-10 transition-all flex flex-col items-center justify-start">
              <img src={data.image} className="w-3/4 md:w-[200px] mb-2 md:mb-6" />
              <h3 className="font-extralight mb-0 mt-0 text-xl md:text-2xl text-medMainGreen text-center">{data.title}</h3>
            </div>
          </div>
        ))}
      </div>
    </section>
  );
});

export default OurCapabilitiesSection;
