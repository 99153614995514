import React, { forwardRef } from "react";
import MedicalGradeFlowerImage1 from "../../../assets/pages/index/MedicalGradeFlowerImage1.png";
import { PrimaryButton } from "../../Common/Button";
import YieldControl from "../../../assets/pages/index/YieldControl.svg";
import NatureOptimization from "../../../assets/pages/index/NatureOptimization.svg";
import AutomatedFertigation from "../../../assets/pages/index/AutomatedFertigation.svg";
import DataDriven from "../../../assets/pages/index/DataDriven.svg";
import PrimaryTree from "../../../assets/pages/index/PrimaryTree.svg";
import SecondaryTree from "../../../assets/pages/index/SecondaryTree.svg";
import TertiaryTree from "../../../assets/pages/index/TertiaryTree.svg";
import { navigate } from "gatsby";

interface LifeCycleSectionProps {
  className?: string;
  animate?: boolean;
}

const ListData = [
  {
    id: "Yield Control",
    title: "Yield Control",
    image: YieldControl,
  },
  {
    id: "Nature Optimization",
    title: "Nature Optimization",
    image: NatureOptimization,
  },
  {
    id: "Automated Fertigation",
    title: "Automated Fertigation",
    image: AutomatedFertigation,
  },
  {
    id: "Data Driven",
    title: "Data Driven",
    image: DataDriven,
  },
];

const LifeCycleSection = forwardRef(({ className, animate }: LifeCycleSectionProps, ref: any) => {
  return (
    <section
      className={`relative flex flex-col items-center md:px-16 px-4 pb-14 md:pb-0 
    transition-all ease-out duration-2500 translate-y-10`}
      style={animate ? { opacity: 1, transform: "translateZ(0px)" } : { opacity: 0 }}
      ref={ref}
      id="capabilities"
    >
      <div className="xl:h-[705px] grid grid-cols-12 gap-0 gap-y-0 w-full max-w-screen-xl bg-medEarthling rounded-[20px] py-10 md:py-14 xl:py-20">
        <div
          className="col-span-12 md:col-span-6 flex flex-col items-end justify-center 
            md:pl-[32px] xl:pl-[50px] xl:pr-[112px] lg:pr-[80px]
            px-4 md:px-0"
        >
          <h3 className="mb-6 text-medMainGreen text-b1 lg:text-h3 text-right">High Quality Genetics</h3>
          <p className="mb-4 md:mb-6 text-cap md:text-b2 text-medMainGreen text-right">
            Experience unique terpenes profiles with our genetics which are meticulously cared for in every stage of the cultivation process.
          </p>
          <PrimaryButton onClick={() => navigate("/genetics")}>Learn more</PrimaryButton>
        </div>
        <div
          className="col-span-12 md:col-span-6 flex justify-between items-center 
        h-[300px] md:h-[400px] xl:h-[545px] 
        px-4 md:pr-[30px] xl:pr-[60px]"
        >
          <img src={PrimaryTree} className="h-[20%] md:h-[20%] ml-[6%]" />
          <img src={SecondaryTree} className="h-[50%] md:h-[45%] md:ml-[10%]" />
          <img src={TertiaryTree} className="h-[80%] md:h-[80%] md:ml-[10%]" />
        </div>
      </div>
    </section>
  );
});

export default LifeCycleSection;
