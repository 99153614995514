import React, { forwardRef } from "react";
import MedicalGradeFlowerImage1 from "../../../assets/pages/index/MedicalGradeFlowerImage1.png";
import { PrimaryButton } from "../../Common/Button";
import YieldControl from "../../../assets/pages/index/YieldControl.svg";
import NatureOptimization from "../../../assets/pages/index/NatureOptimization.svg";
import AutomatedFertigation from "../../../assets/pages/index/AutomatedFertigation.svg";
import DataDriven from "../../../assets/pages/index/DataDriven.svg";
import { navigate } from "gatsby";

interface FacilitySectionProps {
  className?: string;
  animate?: boolean;
}

const ListData = [
  {
    id: "Yield Control",
    title: "Yield Control",
    image: YieldControl,
  },
  {
    id: "Nature Optimization",
    title: "Nature Optimization",
    image: NatureOptimization,
  },
  {
    id: "Automated Fertigation",
    title: "Automated Fertigation",
    image: AutomatedFertigation,
  },
  {
    id: "Data Driven",
    title: "Data Driven",
    image: DataDriven,
  },
];

const FacilitySection = forwardRef(({ className, animate }: FacilitySectionProps, ref: any) => {
  return (
    <section
      className={`relative h-auto flex flex-col items-center md:px-16 py-10 pb-14 md:py-20 transition-all ease-out duration-2500 translate-y-10`}
      style={animate ? { opacity: 1, transform: "translateZ(0px)" } : { opacity: 0 }}
      ref={ref}
      id="capabilities"
    >
      <div
        className="grid grid-cols-12 gap-0 gap-y-0 gap-x-[16px] md:gap-x-[40px] xl:gap-x-[100px] 
      w-full max-w-screen-xl
      px-4 md:px-0"
      >
        <div
          className="col-span-12 md:col-span-6 mb-4 md:mb-0
        xl:h-[665px] lg:h-[600px] md:h-[500px] sm:h-[450px] h-[400px]"
        >
          <img src={MedicalGradeFlowerImage1} className="h-full object-cover w-full rounded-[20px]" />
        </div>
        <div className="col-span-12 md:col-span-6 flex flex-col justify-center xl:pr-[160px] lg:pr-[80px]">
          <h3 className="mb-4 md:mb-6 text-medMainGreen text-b1 lg:text-h3">
            State-of-the-art <br />
            Indoor Facility
          </h3>
          <p className="mb-4 md:mb-6 text-cap md:text-b2 text-medMainGreen">
            To ensure the highest quality cannabis and floral biomass, our facility is equipped with best-in-class smart farming technologies and
            solutions.
          </p>
          <ul className="flex flex-col gap-y-4 mb-4 md:mb-6">
            {ListData.map((item: any) => (
              <li className="text-cap text-medMainGreen flex items-center gap-x-2" key={item.key}>
                <img src={item.image} className="h-12" />
                {item.title}
              </li>
            ))}
          </ul>
          <PrimaryButton onClick={() => navigate("/facility")}>Learn more</PrimaryButton>
        </div>
      </div>
    </section>
  );
});

export default FacilitySection;
